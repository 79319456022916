const groups = [
    {
        label: "Code & Coffee NYC",
        members: [
            {
                firstName: "Member",
                lastName: "One",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["CSS", "HTML", "JavaScript", "React", "Web Dev"],
            },
            {
                firstName: "Member",
                lastName: "Two",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["React"],
            },
            {
                firstName: "Member",
                lastName: "Three",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["HTML"],
            },
            {
                firstName: "Member",
                lastName: "Four",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["CSS"],
            },
            {
                firstName: "Member",
                lastName: "Five",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["JavaScript"],
            },
        ],
    },
    {
        label: "Code & Coffee BOS",
        members: [
            {
                firstName: "Member",
                lastName: "Four",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["React", "Web Dev"],
            },
            {
                firstName: "Member",
                lastName: "Five",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["React"],
            },
            {
                firstName: "Member",
                lastName: "Six",
                imageUrl: "https://placehold.it/100x100",
                skillTags: [],
            },
        ],
    },
    {
        label: "Code & Coffee LA",
        members: [
            {
                firstName: "Member",
                lastName: "Seven",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["React", "HTML", "CSS"],
            },
            {
                firstName: "Member",
                lastName: "Eight",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["JavaScript"],
            },
            {
                firstName: "Member",
                lastName: "Nine",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["Web Dev"],
            },
        ],
    },
    {
        label: "Code & Coffee SF",
        members: [
            {
                firstName: "Member",
                lastName: "Ten",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["React", "HTML", "CSS"],
            },
            {
                firstName: "Member",
                lastName: "Eleven",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["JavaScript"],
            },
            {
                firstName: "Member",
                lastName: "Twelve",
                imageUrl: "https://placehold.it/100x100",
                skillTags: ["Web Dev"],
            },
        ],
    },
];

export default groups;