import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const MemberCard = ({ member }) => {
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={member.imageUrl} />
                </ListItemAvatar>
                <ListItemText
                    primary={member.firstName + " " + member.lastName}
                    secondary={
                        <>
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                You have this in common:
                            </Typography>
                            <br />
                            {member.skillTags.map((skillTag, index) => (
                                <span key={index} className="skillTag">
                                    {skillTag + (index !== member.skillTags.length - 1 ? ", " : "")}
                                </span>
                            ))}
                        </>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};

export default MemberCard;
