import { useEffect } from "react";
import { useSearchParams, useNavigate} from "react-router-dom";

const MeetupAuth = () => {
    let [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const code = searchParams.get("code");

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/auth/meetup/callback?code=${code}`;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem("token", data.token);
                localStorage.setItem("username", data.user.name);
                localStorage.setItem("userId", data.user.id);

                navigate("/chat");
            });
    }, [searchParams, navigate, code]);
    return (
        <div>
            <h1>Meetup Auth</h1>
        </div>
    );
};

export default MeetupAuth;
