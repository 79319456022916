import { Routes as ReactRouterRoutes, Route } from "react-router-dom";

import Home from "./pages/Home";

import Layout from "./Layout";
import NoMatch from "./NoMatch";

import Alerts from "./pages/Alerts";
import Browse from "./pages/Browse";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import Search from "./pages/Search";
import MeetupAuth from "./pages/MeetupAuth";

const Routes = () => {
    return (
        <ReactRouterRoutes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/browse" element={<Browse />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/search" element={<Search />} />
                <Route path="/auth/meetup/callback" element={<MeetupAuth />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </ReactRouterRoutes>
    );
};

export default Routes;
