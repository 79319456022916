import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Home from "@mui/icons-material/HomeOutlined";
import Search from "@mui/icons-material/SearchOutlined";
import NotificationsNone from "@mui/icons-material/NotificationsNone";
import Folder from "@mui/icons-material/FolderOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";

function Layout() {
    const [value, setValue] = useState(0);

    return (
        <>
            <Outlet />
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                className="home--bottomNav"
            >
                <BottomNavigationAction value={1} LinkComponent={Link} to="/" icon={<Home />} />
                <BottomNavigationAction value={2} LinkComponent={Link} to="/search" icon={<Search />} />
                <BottomNavigationAction value={3} LinkComponent={Link} to="/alerts" icon={<NotificationsNone />} />
                <BottomNavigationAction value={4} LinkComponent={Link} to="/browse" icon={<Folder />} />
                <BottomNavigationAction value={5} LinkComponent={Link} to="/profile" icon={<PersonOutline />} />
            </BottomNavigation>
        </>
    );
}

export default Layout;
