import "./App.css";
import Routes from "./Routes";

function App() {
    return (
        <div className="app">
            <Routes />
        </div>
    );
}

export default App;
