import { useEffect, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import List from "@mui/material/List";

import MemberCard from "../components/MemberCard";
import groups from "../data/groups.js";

import "./Home.css";

const Home = () => {
    const [timeTillReset, setTimeTillReset] = useState(0);

    // show how long it will be until midnight padded and not military time
    function pad(number) {
        return number.toString().padStart(2, "0");
    }
    useEffect(() => {
        const interval = setInterval(() => {
            const date = new Date();
            const hours = 23 - date.getHours();
            const minutes = pad(59 - date.getMinutes());
            const seconds = pad(59 - date.getSeconds());

            const paddedTime = `${hours}:${minutes}:${seconds}`;

            setTimeTillReset(paddedTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeTillReset]);

    const members = groups[0].members;
    return (
        <div>
            <div className="home-header">
                <div className="home--title">Weekly Connect</div>
                <div className="home--messageNav">
                    <Link to="/chat">
                        <ChatBubbleOutlineIcon className="home--messageIcon" />
                    </Link>
                </div>
                <Autocomplete
                    className="group--select"
                    options={groups}
                    value={groups[0]}
                    sx={{ width: "100%", margin: "0 auto" }}
                    renderInput={(params) => <TextField {...params} label="Group" />}
                />
                <div className="home--resetTimer">
                    {timeTillReset ? <>Resets in {timeTillReset}</> : <>Loading...</>}
                </div>
            </div>
            <div className="home-body">
                <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {members.map((member, index) => (
                        <MemberCard member={member} key={index} />
                    ))}
                </List>
            </div>
        </div>
    );
};

export default Home;
