import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

import { socket } from "../socket";

const Chat = () => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [username, setUsername] = useState("");

    useEffect(() => {
        const usernameFromLocalStorage = localStorage.getItem("username");
        const userId = localStorage.getItem("userId");

        if (usernameFromLocalStorage && userId) {
            socket.emit("loginUser", userId, usernameFromLocalStorage, (res) => {
                if (res.status === "SUCCESS") {
                    setUsername(res.user.username);
                }
            });
        }

        socket.emit("getMessages", (messages) => {
            setMessages(messages);
        });

        socket.on("refreshMessages", (messages) => {
            setMessages(messages);
        });

        socket.on("loginUser", (user) => {
            console.log(user);
        });

        socket.on("error", (error) => {
            if (error.type === "INVALID_USER_ID") {
                console.error(error.message);
                localStorage.removeItem("userId");
            }
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        socket.emit("addMessage", message, (messages) => {
            setMessages(messages);
        });
        setMessage("");
    };

    if (!username) {
        return (
            <Button
                color="error"
                variant="contained"
                endIcon={<SendIcon />}
                component={Link}
                to={process.env.REACT_APP_API_URL + "/auth/meetup/login"}
            >
                Login with Meetup
            </Button>
        );
    }

    return (
        <>
            <h1>Chat</h1>
            <h2>Welcome, {username}</h2>

            <form onSubmit={handleSubmit}>
                <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                <button>Send</button>
            </form>
            <ul>
                {messages.map((message, index) => {
                    return (
                        <li key={index}>
                            {message.content}
                            <span style={{ fontSize: ".5em" }}>{message.author}</span>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default Chat;
